import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { accountService } from "../../_services";
import moment from "moment";
import "./List.css";

function List({ match }) {
  const { path } = match;
  const [users, setUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    accountService.getAll().then((x) => setUsers(x));
  }, []);

  function handleDeleteClick(user) {
    setSelectedUser(user);
    setShowDialog(true);
  }

  function confirmDelete() {
    const id = selectedUser.id;
    setUsers(
      users.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    accountService.delete(id).then(() => {
      setUsers((users) => users.filter((x) => x.id !== id));
      setShowDialog(false); 
      setShowSuccessMessage(true); 
      setTimeout(() => setShowSuccessMessage(false), 3000); 
    });
  }

  function cancelDelete() {
    setShowDialog(false); 
  }

  const filteredUsers = users&&users.filter(user => !user.parentUser).filter((user) => {
    const fullName = `${user.title} ${user.firstName} ${user.lastName}`.toLowerCase();
    return (
      fullName.includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.role.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "280px",
            marginRight: "auto",
            paddingLeft: "20px",
          }}
        />
        <Link
          style={{ float: "right" }}
          to={`${path}/add`}
          className="btn-custom"
        >
          Add User
        </Link>
      </div>

      {showSuccessMessage && (
        <div className="alert alert-success" role="alert">
          User deleted successfully!
        </div>
      )}

      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: "30%" }}>Name</th>
            <th style={{ width: "30%" }}>Email</th>
            <th style={{ width: "30%" }}>Role</th>
            <th style={{ width: "30%" }}>Join On</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers && filteredUsers.length > 0 ? (
            filteredUsers.filter(user => !user.parentUser).map((user) => (
              <tr key={user.id}>
                <td style={{fontWeight:"bold"}}>{user.title} {user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  {/^[a-f0-9]{24}$/i.test(user.role) ? (
                    <span className="role-chip member-chip">Member</span>
                  ) : user.role.toUpperCase() === "ADMIN" ? (
                    <span className="role-chip admin-chip">Admin</span>
                  ) : user.role.toUpperCase() === "SUPER-ADMIN" ? (
                    <span className="role-chip super-admin-chip">Super Admin</span>
                  ) : (
                    <span className="role-chip member-chip">"MEMBER"</span>
                  )}
                </td>
                <td style={{ whiteSpace: "nowrap",fontSize:"0.8em" }}>{moment(user.createdDate).format("DD/MMM/YY HH:MM")}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {user.role !=="SUPER-ADMIN" && <button
                    onClick={() => handleDeleteClick(user)}
                    className="btn btn-sm btn-danger mr-2"
                    disabled={user.isDeleting}
                  >
                    {user.isDeleting ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <span>Delete</span>
                    )}
                  </button>}
                  <Link to={`${path}/view/${user.id}`} className="btn btn-sm btn-primary mr-2">
                    View
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <h5>Confirm Delete User</h5>
            <p>
              Are you sure you want to delete user <strong>"{selectedUser.firstName} {selectedUser.lastName}"</strong>?
            </p>
            <div className="dialog-buttons">
              <button onClick={confirmDelete} className="btn btn-sm btn-danger">
                OK
              </button>
              <button onClick={cancelDelete} className="btn btn-sm btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { List };
